/* eslint-disable react/display-name */
import MaterialTable from '@material-table/core';
import AddBox from '@mui/icons-material/AddBox';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Preview from '@mui/icons-material/PreviewRounded';
import Refresh from '@mui/icons-material/Refresh';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import Print from '@mui/icons-material/Print';
import ViewColumn from '@mui/icons-material/ViewColumn';
import OpenInFullRoundedIcon from '@mui/icons-material/OpenInFullRounded';
import { ExportCsv, ExportPdf } from '@material-table/exporters';
import { forwardRef, createRef, useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from '@mui/material';
import { saveAs } from 'file-saver';
import { PDFDocument, Download } from 'pdf-lib';
import { DownloadIcon } from '@heroicons/react/outline';
// Needed for material-table
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  Refresh: forwardRef((props, ref) => <Refresh {...props} ref={ref} />),
  Print: forwardRef((props, ref) => <Print {...props} ref={ref} />),
  CancelScheduleSend: forwardRef((props, ref) => (
    <Preview {...props} ref={ref} />
  )),
  OpenInFullRoundedIcon: forwardRef((props, ref) => (
    <OpenInFullRoundedIcon {...props} ref={ref} />
  )),
};

const tableRef = createRef();

const tableHeight = ((window.innerHeight - 230) / window.innerHeight) * 100;

const mergePdfs = async (pdfsToMerge) => {
  const mergedPdf = await PDFDocument.create();
  const actions = pdfsToMerge.map(async (pdfBuffer) => {
    const pdf = await PDFDocument.load(pdfBuffer);
    const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
    copiedPages.forEach((page) => {
      mergedPdf.addPage(page);
    });
  });
  await Promise.all(actions);
  const mergedPdfFile = await mergedPdf.save();
  return mergedPdfFile;
};

// these are temp
const columns = [
  { title: 'Date', field: 'Date', type: 'datetime' },
  { title: 'Docket No', field: 'serial' },
  { title: 'Vehicle Reg', field: 'registration' },
  { title: 'Customer', field: 'company' },
  { title: 'Job Type', field: 'jobtype' },
  { title: 'Job No', field: 'jobno' },
  { title: 'Origin', field: 'origin' },
  { title: 'Destination', field: 'destination' },
  { title: 'Waste Desc', field: 'wastedesc' },
  { title: 'Charge Basis', field: 'basis' },
  { title: 'Loads', field: 'Loads' },
  { title: 'Hours', field: 'hours' },
  { title: 'Tonne', field: 'tonne' },
  { title: 'Notes', field: 'notes' },
  { title: 'Paid/Unpaid', field: 'paid' },
  { title: 'Hire Desc', field: 'hirearea' },
];
const data = [
  {
    date: '21-Jan-22',
    customer: 'Test Company',
    desc: 'Job 200',
    origin: 'Ballincollig',
    destination: 'Cork City',
  },
];
//

export default function Dockets({ jobAdded }) {
  useEffect(() => {
    if (!jobAdded) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
    //reload the api so the table reloads.
  }, [jobAdded]);
  const [loading, setLoading] = useState(false);

  return (
    <MaterialTable
      icons={tableIcons}
      title='Active Jobs'
      isLoading={loading}
      tableRef={tableRef}
      localization={{
        toolbar: { searchPlaceholder: 'Docket, Company, Reg' },
        body: {
          emptyDataSourceMessage: (
            <p className='mt-2 text-sm text-gray-500'>No Data Here.</p>
          ),
        },
      }}
      columns={columns}
      data={(query) =>
        new Promise((resolve) => {
          const p_start = query.page === 0 ? 0 : query.pageSize * query.page;
          axios
            .get(
              `https://doca-app.com/api/getDocket?p_start=${p_start}&p_limit=${query.pageSize}&p_query=${query.search}`
            )
            .then((result) => {
              if (result.data.hasErrors) {
                resolve({
                  data: [],
                  page: query.page,
                  totalCount: 0,
                });
              } else {
                resolve({
                  data: result.data.result,
                  page: query.page,
                  totalCount: result.data.total.count,
                });
              }
            });
        })
      }
      options={{
        exportAllData: true, //Flag for export all data instead of rendered data
        columnsButton: true,
        selection: true,
        debounceInterval: 500,
        maxBodyHeight: `${tableHeight}vh`,
        minBodyHeight: `${tableHeight}vh`,
        pageSize: 10, // make initial page size
        emptyRowsWhenPaging: false, // To avoid of having empty rows
        //maxBodyHeight: `${tableHeight}vh`,
        pageSizeOptions: [5, 10, 20],
        search: true,
        exportMenu: [
          {
            label: 'Export PDF',
            exportFunc: (cols, datas) => ExportPdf(cols, datas, 'QueryResult'),
          },
          {
            label: 'Export CSV',
            exportFunc: (cols, datas) => ExportCsv(cols, datas, 'QueryResult'),
          },
        ],
        headerStyle: {
          zIndex: 2,
          position: 'sticky',
        },
      }}
      actions={[
        {
          icon: Print,
          tooltip: 'Print',
          onClick: async (event, data) => {
            // Array for each pdf file you want to download.
            let files = [];
            // Loop therough the selected table rows for each docket pdf and fetch it
            // Add it to the files array
            for (let i = 0; i < data.length; i++) {
              let blob = await fetch(data[i].docket).then((r) =>
                r.arrayBuffer()
              );
              files.push(blob);
            }

            // Now merge the files
            const mergedPdf = await mergePdfs(files);
            // Convert the arraybuffer to blob
            const blobPdf = new Blob([mergedPdf], { type: 'application/pdf' });
            //Download
            saveAs(blobPdf, `Dockets_${new Date()}.pdf`);
          },
        },
        {
          icon: Refresh,
          tooltip: 'Refresh Data',
          isFreeAction: true,
          onClick: () => tableRef.current.onQueryChange(),
        },
      ]}
    />
  );
}
