// Date picker component

// Props:
// onDateChanged : function that runs when the date changes.
// showTime: true to show a time selection as well as date.
// timeInterval: Interval between times on the time selection, defaults to 30.

import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import isSameDay from 'date-fns/isSameDay';
import endOfDay from 'date-fns/endOfDay';
import startOfToday from 'date-fns/startOfToday';
import 'react-datepicker/dist/react-datepicker.css';

// If the date selected is today, only show times greater than the current time. (new date returns the current time)
// If the date selected is not today show all times.(startOfToday)
const calculateMinTime = (date) =>
  isSameDay(date, new Date()) ? new Date() : startOfToday();

export default function ResponsiveDatePickers({
  onDateChanged,
  showTime,
  timeInterval,
}) {
  // Use state to store the selected date and time.
  const [startDate, setStartDate] = useState(
    setHours(setMinutes(new Date(), 0), 0)
  );
  // The difference between the time options, default 30. i.e
  // 10:00, 10:30, 11:00, 11:30
  const interval = timeInterval || 30;
  return (
    <DatePicker
      selected={startDate}
      timeIntervals={interval}
      minDate={new Date()}
      onChange={(date) => {
        setStartDate(date);
        onDateChanged(date);
        calculateMinTime(date);
      }}
      showTimeSelect={showTime}
      minTime={calculateMinTime(startDate)} // check the date in caculateMinTime Func.
      maxTime={endOfDay(new Date())} // always end of day so all times are shown.
      dateFormat='d-MMM-Y HH:mm'
    />
  );
}
