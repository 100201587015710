// This hook is used to load a low quality image with a blurred effect before loading the actual image.
// This is used so that the image displays gracefully.

import React from 'react';
const useProgressiveImg = (lowQualitySrc, highQualitySrc) => {
  const [src, setSrc] = React.useState(lowQualitySrc);
  React.useEffect(() => {
    // Initially use the low qual image
    setSrc(lowQualitySrc);
    // Start loading the high qual one.
    const img = new Image();
    img.src = highQualitySrc;
    img.onload = () => {
      // high qual loaded, now use this.
      setSrc(highQualitySrc);
    };
  }, [lowQualitySrc, highQualitySrc]);

  // return blur as true only if the src is the lowquality image.
  return [src, { blur: src === lowQualitySrc }];
};
export default useProgressiveImg;
