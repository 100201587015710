import axios from 'axios';
import PropTypes from 'prop-types';
import { React, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useProgressiveImg from '../useProgressiveImage';

const BlurredUpImage = () => {
  const [src, { blur }] = useProgressiveImg(
    process.env.PUBLIC_URL + '/lowqual.jpeg',
    process.env.PUBLIC_URL + process.env.REACT_APP_LOGIN_BG
  );
  return (
    <img
      alt='bg'
      className='absolute inset-0 h-full w-full object-cover'
      src={src}
      style={{
        filter: blur ? 'blur(20px)' : 'none',
        transition: blur ? 'none' : 'filter 0.3s ease-out',
      }}
    />
  );
};

// We also use blurred images(useProgressiveImg hook) for the logos, although they are not high quality and load fairly fast
// we still want a blurred effect while they load.
// the blurred image here is probably around the same size as the actual one.
const BlurredUpLogo = () => {
  const [src, { blur }] = useProgressiveImg(
    process.env.PUBLIC_URL + '/logo_blur.png',
    process.env.PUBLIC_URL + process.env.REACT_APP_LOGO
  );
  return (
    <img
      alt='logo'
      className='h-12 w-auto'
      src={src}
      style={{
        filter: blur ? 'blur(20px)' : 'none',
        transition: blur ? 'none' : 'filter 0.3s ease-out',
      }}
    />
  );
};
function Login({ setToken, setDetail }) {
  // When the login button is clicked we call HandleClick, this calls loginUser and we await the promise.
  // Once we are logged in, we call support which is basically out boot file that contails all the user data.
  // We store the token and userdetails, this causes a re-render and the home screen is shown.
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [isLoading, setLoading] = useState(false);
  // Unique id for the toast.
  const toastId = useRef(null);
  // initially we will have a loading toast.
  // errorToast, when called will update that toast to invalid password
  const loadingToast = () =>
    (toastId.current = toast.loading('Logging In', { autoClose: false }));
  const errorToast = (errorMsg) =>
    toast.update(toastId.current, {
      render: errorMsg,
      type: 'error',
      isLoading: false,
      autoClose: 5000,
    });
  //This needs to be used to return toast to the user when the password is wrong, will need to set it in loginuser function or
  // catch an error and set there
  async function HandleClick(e) {
    e.preventDefault();
    const username = e.target[0].value;
    const password = e.target[1].value;
    const rememberMe = e.target[2].checked;
    // Spinner on sign in form
    setLoading(true);
    //Call
    loadingToast();
    // Login the User
    LoginUser({
      username,
      password,
      rememberMe,
    });
  }

  /**
   *
   * @param {Object} credentials
   * Login the User to Hubble
   */
  async function LoginUser(credentials) {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('p_username', credentials.username);
      bodyFormData.append('p_password', credentials.password);
      // Login API
      const loginResponse = await axios({
        method: 'post',
        url: `https://doca-app.com/api/login`,
        data: {
          p_username: credentials.username,
          p_password: credentials.password,
        },
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      });
      if (loginResponse.data.message === 'Invalid Username/Password')
        throw Error('InvalidPassword');

      // This gives the user a prompt to save the password on the browser.
      if (credentials.rememberMe) {
        // eslint-disable-next-line no-undef
        const cred = new PasswordCredential({
          id: username,
          password: password,
        });
        await navigator.credentials.store(cred);
      }
      //handle success
      if (loginResponse) setToken('yes');

      setLoading(false);

      //end of try
    } catch (error) {
      if (error.code === 'ERR_NETWORK') {
        errorToast('Network Issue');
      }
      if (error.message === 'InvalidPassword') {
        errorToast('Invalid Username/Password');
        setLoading(false);
      } else if (error.message === 'CookieError') {
        errorToast('Cookie Error');
        setLoading(false);
      } else if (error.message === 'BootError') {
        errorToast('Boot Error');
        setLoading(false);
      } else {
        errorToast('Unknown Error');
        console.log(error);
        setLoading(false);
      }
    }
  }
  return (
    <div className='min-h-screen bg-white flex'>
      <div className='flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24'>
        <div className='mx-auto w-full max-w-sm lg:w-96'>
          <div>
            {BlurredUpLogo()}
            {!isLoading ? (
              <h2 className='mt-6 text-3xl font-extrabold text-gray-900'>
                Sign in to your account
              </h2>
            ) : (
              ''
            )}
          </div>

          <div className='mt-8'>
            {isLoading ? (
              <div class=' flex justify-center items-center'>
                <div class='animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-red-500 justify-center'></div>
              </div>
            ) : (
              ''
            )}
            <div>
              <div>
                <div className='mt-1 grid grid-cols-3 gap-3'></div>
              </div>
            </div>

            <div className='mt-6'>
              <form action='#' className='space-y-6' onSubmit={HandleClick}>
                <div>
                  <label
                    htmlFor='email'
                    className='block text-sm font-medium text-gray-700'
                  >
                    Username
                  </label>
                  <div className='mt-1'>
                    <input
                      id='username'
                      name='username'
                      type='text'
                      autoComplete='username'
                      required
                      onChange={(e) => setUserName(e.target.value)}
                      className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm'
                    />
                  </div>
                </div>

                <div className='space-y-1'>
                  <label
                    htmlFor='password'
                    className='block text-sm font-medium text-gray-700'
                  >
                    Password
                  </label>
                  <div className='mt-1'>
                    <input
                      id='current-password'
                      name='password'
                      type='password'
                      autoComplete='current-password'
                      required
                      onChange={(e) => setPassword(e.target.value)}
                      className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm'
                    />
                  </div>
                </div>

                <div className='flex items-center justify-between'>
                  <div className='flex items-center'>
                    <input
                      id='remember-me'
                      name='remember-me'
                      type='checkbox'
                      className='h-4 w-4 text-red-600 focus:ring-red-500 border-gray-300 rounded'
                    />
                    <label
                      htmlFor='remember-me'
                      className='ml-2 block text-sm text-gray-900'
                    >
                      Remember me
                    </label>
                  </div>
                </div>

                <div>
                  <button
                    type='submit'
                    onClick={(e) => e.currentTarget.blur()}
                    className='w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
                  >
                    Sign in
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className='hidden lg:block relative w-0 flex-1'>
        {BlurredUpImage()}
      </div>
    </div>
  );
}
Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};

export default Login;
