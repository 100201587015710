import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { LoadScript } from '@react-google-maps/api';
import Home from './pages/Home';
import useToken from './useToken';
import Login from './pages/Login';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const loading = (
  <div className='flex h-full'>
    <div className='top-1/2 left-1/2 absolute'>
      <div className='animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-red-500 justify-center'></div>
    </div>
  </div>
);
function App() {
  const { token, setToken } = useToken();
  return (
    <Router>
      <LoadScript
        loadingElement={loading}
        googleMapsApiKey={'AIzaSyBou3xK1ikAKy2HgXvrftZ8lyL2nKcdlAA'}
        libraries={['places', 'google-map-script']}
      >
        <ToastContainer
          position='top-right'
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick={true}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {token ? <Home /> : <Login setToken={setToken} />}
      </LoadScript>
    </Router>
  );
}

export default App;
