/* eslint-disable react/display-name */
import MaterialTable from '@material-table/core';
import AddBox from '@mui/icons-material/AddBox';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Preview from '@mui/icons-material/PreviewRounded';
import Refresh from '@mui/icons-material/Refresh';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import Send from '@mui/icons-material/SendRounded';
import ViewColumn from '@mui/icons-material/ViewColumn';
import OpenInFullRoundedIcon from '@mui/icons-material/OpenInFullRounded';
import { ExportCsv, ExportPdf } from '@material-table/exporters';
import { forwardRef, createRef, useEffect, useState } from 'react';
import axios from 'axios';
import JobDetail from '../Components/JobDetail';

// Needed for material-table
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  Refresh: forwardRef((props, ref) => <Refresh {...props} ref={ref} />),
  Send: forwardRef((props, ref) => <Send {...props} ref={ref} />),
  CancelScheduleSend: forwardRef((props, ref) => (
    <Preview {...props} ref={ref} />
  )),
  OpenInFullRoundedIcon: forwardRef((props, ref) => (
    <OpenInFullRoundedIcon {...props} ref={ref} />
  )),
};

const tableRef = createRef();

const tableHeight = ((window.innerHeight - 230) / window.innerHeight) * 100;

// these are temp
const columns = [
  { title: 'Date', field: 'date', type: 'datetime' },
  { title: 'Customer', field: 'customer' },
  { title: 'Collection Point', field: 'origin' },
  { title: 'Destination', field: 'destination' },
];
const data = [
  {
    date: '21-Jan-22',
    customer: 'Test Company',
    desc: 'Job 200',
    origin: 'Ballincollig',
    destination: 'Cork City',
  },
];
//
let geoLocation = {};
export default function Jobs({ jobAdded }) {
  useEffect(() => {
    if (!jobAdded) {
      tableRef.current.onQueryChange();
    }
    //reload the api so the table reloads.
  }, [jobAdded]);
  const [loading, setLoading] = useState(false);
  const [showJob, setShowJob] = useState(false);

  const showJobDetail = (data) => {
    setShowJob(true);
    geoLocation = {
      originGeoCode: data.origin_geo_code,
      destinationGeoCode: data.destination_geo_code,
    };
  };

  const hideShowJob = () => {
    setShowJob(false);
  };

  return (
    <>
      <JobDetail
        show={showJob}
        handleClose={hideShowJob}
        geoLocation={geoLocation}
      ></JobDetail>
      <MaterialTable
        icons={tableIcons}
        title='Active Jobs'
        isLoading={loading}
        tableRef={tableRef}
        localization={{
          body: {
            emptyDataSourceMessage: (
              <p className='mt-2 text-sm text-gray-500'>No Data Here.</p>
            ),
          },
        }}
        columns={columns}
        data={(query) =>
          new Promise((resolve) => {
            const p_start = query.page === 0 ? 0 : query.pageSize * query.page;
            axios
              .get(
                `https://doca-app.com/api/getJob?p_start=${p_start}&p_limit= ${query.pageSize}`
              )
              .then((result) => {
                if (result.data.hasErrors) {
                  resolve({
                    data: [],
                    page: query.page,
                    totalCount: 0,
                  });
                } else {
                  resolve({
                    data: result.data.result,
                    page: query.page,
                    totalCount: result.data.total.count,
                  });
                }
              });
          })
        }
        actions={[
          {
            icon: Refresh,
            tooltip: 'Refresh Data',
            isFreeAction: true,
            onClick: () => tableRef.current.onQueryChange(),
          },
          {
            icon: Preview,
            tooltip: 'View on Map',
            onClick: (e, data) => {
              showJobDetail(data);
            },
          },
          {
            icon: OpenInFullRoundedIcon,
            tooltip: 'Expand Result',
            isFreeAction: true,
            onClick: () => {
              console.log('fullscreen');
            },
          },
        ]}
        options={{
          exportAllData: true, //Flag for export all data instead of rendered data
          columnsButton: true,
          maxBodyHeight: `${tableHeight}vh`,
          minBodyHeight: `${tableHeight}vh`,
          pageSize: 10, // make initial page size
          emptyRowsWhenPaging: false, // To avoid of having empty rows
          //maxBodyHeight: `${tableHeight}vh`,
          pageSizeOptions: [5, 10, 20],
          search: false,
          exportMenu: [
            {
              label: 'Export PDF',
              exportFunc: (cols, datas) =>
                ExportPdf(cols, datas, 'QueryResult'),
            },
            {
              label: 'Export CSV',
              exportFunc: (cols, datas) =>
                ExportCsv(cols, datas, 'QueryResult'),
            },
          ],
          headerStyle: {
            zIndex: 2,
            position: 'sticky',
          },
        }}
      />
    </>
  );
}
