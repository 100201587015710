// Add/Edit User Component.
// props required are show, handleclose, onSave and accountData.
// show: the variable that decides whether the modal is shown or hidden.
// handleClose: The function called when the close button is pressed.
// onSave: The function called when the save button is pressed.
// accountData: For edit mode the userdata is passed in and auto populated.

import { Dialog, Transition } from '@headlessui/react';
import { useRef, Fragment, useState, useEffect } from 'react';
import { Autocomplete } from '@react-google-maps/api';
import { GoogleMap, Marker, DirectionsRenderer } from '@react-google-maps/api';
const containerStyle = {
  width: 'auto',
  height: '400px',
};

export default function JobDetail({ show, handleClose, geoLocation }) {
  const latlngStrOrigin = geoLocation.originGeoCode
    ? geoLocation.originGeoCode.slice(1, -1).split(',', 2)
    : '';
  const latlngOrigin = {
    lat: parseFloat(latlngStrOrigin[0]),
    lng: parseFloat(latlngStrOrigin[1]),
  };

  const latlngStrDest = geoLocation.destinationGeoCode
    ? geoLocation.destinationGeoCode.slice(1, -1).split(',', 2)
    : '';
  const latlngDest = {
    lat: parseFloat(latlngStrDest[0]),
    lng: parseFloat(latlngStrDest[1]),
  };

  const mapRef = useRef();
  const markerFromRef = useRef();
  const markerToRef = useRef();
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [distance, setDistance] = useState('');
  const [duration, setDuration] = useState('');

  async function calculateRoute() {
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: latlngOrigin,
      destination: latlngDest,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    });
    setDirectionsResponse(results);
    setDistance(results.routes[0].legs[0].distance.text);
    setDuration(results.routes[0].legs[0].duration.text);
  }

  useEffect(() => {
    // This effect uses the `value` variable,
    // so it "depends on" `value`.
    if (show) {
      calculateRoute();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);
  return (
    <Transition
      as={Fragment}
      enter='ease-out duration-300'
      enterFrom='opacity-0 translate-y-4 sm:translate-y-0 '
      enterTo='opacity-100 translate-y-0 sm:scale-100'
      leave='ease-in duration-200'
      leaveFrom='opacity-100 translate-y-0 sm:scale-100'
      leaveTo='opacity-0 translate-y-4 sm:translate-y-0'
      show={show}
    >
      <Dialog
        as='div'
        className='fixed z-10 inset-0 overflow-y-auto backdrop-blur-sm bg-slate-900/50'
        onClose={handleClose}
      >
        <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'
          >
            &#8203;
          </span>

          <div className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl min-w-1/2 transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6'>
            <Dialog.Panel className='rounded bg-white grid grid-cols-4'>
              <div className='col-span-3'>
                <GoogleMap
                  onLoad={(ref) => (mapRef.current = ref)}
                  mapContainerStyle={containerStyle}
                  center={latlngOrigin}
                  zoom={8}
                >
                  {/* Child components, such as markers, info windows, etc. */}
                  {directionsResponse && (
                    <DirectionsRenderer directions={directionsResponse} />
                  )}
                </GoogleMap>
              </div>
              <div className='col-span-1 pl-2 space-y-4'>
                <p>Distance: {distance} </p>
                <p>Job Time: {duration} </p>
                <a
                  className='text-center ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  href={`https://www.google.com/maps/dir/?api=1&destination=${parseFloat(
                    latlngStrOrigin[0]
                  )},${parseFloat(latlngStrOrigin[1])}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  Plot route to origin
                </a>
                <a
                  className='text-center ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  href={`https://www.google.com/maps/dir/?api=1&destination=${parseFloat(
                    latlngStrDest[0]
                  )},${parseFloat(latlngStrDest[1])}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  Plot route to destination
                </a>
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
