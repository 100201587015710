// Add/Edit User Component.
// props required are show, handleclose, onSave and accountData.
// show: the variable that decides whether the modal is shown or hidden.
// handleClose: The function called when the close button is pressed.
// onSave: The function called when the save button is pressed.
// accountData: For edit mode the userdata is passed in and auto populated.

import { Dialog, Transition } from '@headlessui/react';
import { useRef, Fragment, useState } from 'react';
import { Autocomplete } from '@react-google-maps/api';
import { GoogleMap, Marker } from '@react-google-maps/api';
import DatePicker from './common/DatePicker';
const containerStyle = {
  width: '320px',
  height: '100px',
};

const center = {
  lat: 51.890705,
  lng: -8.598575,
};

export default function AddJob({ show, handleClose, onSave }) {
  const [jobDate, setJobDate] = useState();

  const onDateChanged = (date) => {
    setJobDate(date);
  };
  const addFromRef = useRef();
  const addToRef = useRef();

  const mapFromRef = useRef();
  const markerFromRef = useRef();

  const mapToRef = useRef();
  const markerToRef = useRef();

  const onPlaceChanged = (place, from) => {
    const map = from === 'origin' ? mapFromRef : mapToRef;
    const marker = from === 'origin' ? markerFromRef : markerToRef;
    if (!place.geometry || !place.geometry.location) {
      // User entered the name of a Place that was not suggested and
      // pressed the Enter key, or the Place Details request failed.
      window.alert("No details available for input: '" + place.name + "'");
      return;
    }

    //If the place has a geometry, then present it on a map.
    if (place.geometry.viewport) {
      map.current.fitBounds(place.geometry.viewport);
    } else {
      map.current.setCenter(place.geometry.location);
      map.current.setZoom(17);
    }
    marker.current.setPosition(place.geometry.location);
    marker.current.setVisible(true);
  };

  return (
    <Transition
      as={Fragment}
      enter='ease-out duration-300'
      enterFrom='opacity-0 translate-y-4 sm:translate-y-0 '
      enterTo='opacity-100 translate-y-0 sm:scale-100'
      leave='ease-in duration-200'
      leaveFrom='opacity-100 translate-y-0 sm:scale-100'
      leaveTo='opacity-0 translate-y-4 sm:translate-y-0'
      show={show}
    >
      <Dialog
        as='div'
        className='fixed z-10 inset-0 overflow-y-auto backdrop-blur-sm bg-slate-900/50'
        onClose={handleClose}
      >
        <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'
          >
            &#8203;
          </span>

          <div className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl min-w-1/2 transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6'>
            <Dialog.Panel className='rounded bg-white'>
              <form
                className='space-y-8 divide-y divide-gray-200'
                onSubmit={(e) => {
                  onSave(
                    e,
                    markerFromRef.current.getPosition().toString(),
                    markerToRef.current.getPosition().toString(),
                    jobDate
                  );
                }}
              >
                <div className='space-y-8 divide-y divide-gray-200 sm:space-y-5'>
                  <div>
                    <h3 className='text-lg leading-6 font-medium text-gray-900'>
                      {'Add Job'}
                    </h3>
                  </div>

                  <div className='space-y-6 sm:space-y-5'>
                    <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                      <label
                        htmlFor='region'
                        className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'
                      >
                        Date
                      </label>
                      <div className='mt-1 sm:mt-0 sm:col-span-2'>
                        <DatePicker
                          showTime={true}
                          onDateChanged={onDateChanged}
                        ></DatePicker>
                      </div>
                    </div>

                    <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                      <label
                        htmlFor='region'
                        className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'
                      >
                        Customer
                      </label>
                      <div className='mt-1 sm:mt-0 sm:col-span-2'>
                        <input
                          type='text'
                          required
                          name='region'
                          defaultValue={''}
                          id='region'
                          autoComplete='address-level1'
                          className='border-2 max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md'
                        />
                      </div>
                    </div>
                    <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                      <label
                        htmlFor='region'
                        className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'
                      >
                        Pickup Location
                      </label>
                      <div className='mt-1 sm:mt-0 sm:col-span-2'>
                        <Autocomplete
                          onPlaceChanged={() =>
                            onPlaceChanged(
                              addFromRef.current.getPlace(),
                              'origin'
                            )
                          }
                          onLoad={(ref) => (addFromRef.current = ref)}
                        >
                          <input
                            type='text'
                            required
                            placeholder='Start Typing Address'
                            className='border-2 max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md'
                          />
                        </Autocomplete>
                        <div className='pt-2'>
                          <GoogleMap
                            onLoad={(ref) => (mapFromRef.current = ref)}
                            mapContainerStyle={containerStyle}
                            center={center}
                            zoom={5}
                          >
                            {/* Child components, such as markers, info windows, etc. */}
                            <Marker
                              position={center}
                              onLoad={(ref) => (markerFromRef.current = ref)}
                              visible={false}
                            />
                          </GoogleMap>
                        </div>
                      </div>
                    </div>

                    <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                      <label
                        htmlFor='region'
                        className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'
                      >
                        Devlivery Location
                      </label>
                      <div className='mt-1 sm:mt-0 sm:col-span-2'>
                        <Autocomplete
                          onPlaceChanged={() =>
                            onPlaceChanged(
                              addToRef.current.getPlace(),
                              'destination'
                            )
                          }
                          onLoad={(ref) => (addToRef.current = ref)}
                        >
                          <input
                            type='text'
                            required
                            placeholder='Start Typing Address'
                            className='border-2 max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md'
                          />
                        </Autocomplete>
                        <div className='pt-2'>
                          <GoogleMap
                            onLoad={(ref) => (mapToRef.current = ref)}
                            mapContainerStyle={containerStyle}
                            center={center}
                            zoom={5}
                          >
                            {/* Child components, such as markers, info windows, etc. */}
                            <Marker
                              position={center}
                              onLoad={(ref) => (markerToRef.current = ref)}
                              visible={false}
                            />
                          </GoogleMap>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='pt-5'>
                  <div className='flex justify-end'>
                    <button
                      type='button'
                      className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                      onClick={handleClose}
                    >
                      Cancel
                    </button>
                    <button
                      type='submit'
                      className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
