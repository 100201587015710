/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useContext } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import format from 'date-fns/format';
import {
  BellIcon,
  MenuIcon,
  XIcon,
  HomeIcon,
  BriefcaseIcon,
  DocumentTextIcon,
} from '@heroicons/react/outline';
import { PlusSmIcon } from '@heroicons/react/solid';
import useProgressiveImg from '../useProgressiveImage';
import Dockets from './Dockets';
import Jobs from './Jobs';
import AddJob from '../Components/AddJob';
import JobSpec from './JobSpec';
import {
  NavLink,
  Link,
  Route,
  Routes,
  Navigate,
  useLocation,
} from 'react-router-dom';
import axios from 'axios';
const user = {
  name: 'Tom Cook',
  email: 'tom@example.com',
  imageUrl:
    'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
};
const navigation = [
  {
    name: 'Jobs',
    to: '/jobs',
    icon: BriefcaseIcon,
    current: false,
    visible: 'Y',
  },
  {
    name: 'Dockets',
    to: '/dockets',
    icon: DocumentTextIcon,
    current: false,
    visible: 'Y',
  },
];

const userNavigation = [
  { name: 'Your Profile', href: '#' },
  { name: 'Settings', href: '#' },
  { name: 'Sign out', href: '#' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
// We also use blurred images(useProgressiveImg hook) for the logos, although they are not high quality and load fairly fast
// we still want a blurred effect while they load.
// the blurred image here is probably around the same size as the actual one.
const BlurredUpLogo = () => {
  const [src, { blur }] = useProgressiveImg('/home192.png', '/home_logo.png');
  return (
    <img
      alt='logo'
      className='h-8 w-auto'
      src={src}
      style={{
        filter: blur ? 'blur(20px)' : 'none',
        transition: blur ? 'none' : 'filter 0.3s ease-out',
      }}
    />
  );
};
export default function Home() {
  const [addJob, setAddJob] = useState(false);

  const location = useLocation();
  const hideAddJob = () => {
    setAddJob(false);
  };

  const showAddJob = () => {
    setAddJob(true);
  };
  const handleAddJob = (e, fromGeoCode, toGeoCode, date) => {
    e.preventDefault();
    const dateFormatted = format(date, 'yyyy/MM/dd kk:mm:ss');
    const formVals = e.currentTarget;
    axios.post(`https://doca-app.com/api/postJob`, {
      date: dateFormatted,
      customer: formVals[1].value,
      origin: formVals[2].value,
      originGeoCode: fromGeoCode,
      destination: formVals[14].value,
      destinationGeoCode: toGeoCode,
    });
    setAddJob(false);
  };
  return (
    <div className='h-screen'>
      <Routes>
        <Route path='/jobspec/:id' element={<JobSpec />} />
      </Routes>
      <div
        className={`${
          location.pathname.includes('/jobspec') ? 'invisible' : 'w-full'
        }`}
      >
        <AddJob
          show={addJob}
          handleClose={hideAddJob}
          onSave={handleAddJob}
        ></AddJob>
        <Disclosure as='nav' className='bg-gray-800'>
          {({ open }) => (
            <>
              <div className='max-w-7xl mx-4 px-4 px-2'>
                <div className='flex justify-between h-16'>
                  <div className='flex'>
                    <div className='-ml-2 mr-2 flex items-center md:hidden'>
                      {/* Mobile menu button */}
                      <Disclosure.Button className='inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'>
                        <span className='sr-only'>Open main menu</span>
                        {open ? (
                          <XIcon className='block h-6 w-6' aria-hidden='true' />
                        ) : (
                          <MenuIcon
                            className='block h-6 w-6'
                            aria-hidden='true'
                          />
                        )}
                      </Disclosure.Button>
                    </div>
                    <div className='flex-shrink-0 flex items-center'>
                      {BlurredUpLogo()}
                    </div>
                    <div className='hidden md:ml-6 md:flex md:items-center md:space-x-4'>
                      <nav className='inline-flex px-2 space-y-1 space-x-2'>
                        {navigation.map((item) => (
                          <NavLink
                            key={item.name}
                            to={item.to}
                            className={({ isActive }) =>
                              classNames(
                                'cursor-pointer text-gray-400 hover:bg-gray-700 hover:text-white',
                                'group flex items-center px-2 py-2 text-sm font-medium rounded-md' +
                                  (isActive
                                    ? classNames(' bg-indigo-500 !text-white')
                                    : '')
                              )
                            }
                          >
                            <item.icon
                              className={'mr-3 flex-shrink-0 h-6 w-6'}
                              aria-hidden='true'
                            />
                            {item.name}
                          </NavLink>
                        ))}
                      </nav>
                    </div>
                  </div>
                  <div className='flex items-center absolute right-5 pt-3'>
                    <div className='flex-shrink-0'>
                      <button
                        type='button'
                        className={`${
                          location.pathname !== '/jobs' && 'invisible'
                        } cursor-pointer relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500`}
                        onClick={showAddJob}
                      >
                        <PlusSmIcon
                          className='-ml-1 mr-2 h-5 w-5'
                          aria-hidden='true'
                        />
                        <span>New Job</span>
                      </button>
                    </div>
                    <div className='hidden md:ml-4 md:flex-shrink-0 md:flex md:items-center'>
                      <button
                        type='button'
                        className='bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white'
                      >
                        <span className='sr-only'>View notifications</span>
                        <BellIcon className='h-6 w-6' aria-hidden='true' />
                      </button>

                      {/* Profile dropdown */}
                      <Menu as='div' className='ml-3 relative'>
                        <div>
                          <Menu.Button className='bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white'>
                            <span className='sr-only'>Open user menu</span>
                            <img
                              className='h-8 w-8 rounded-full'
                              src={user.imageUrl}
                              alt=''
                            />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter='transition ease-out duration-200'
                          enterFrom='transform opacity-0 scale-95'
                          enterTo='transform opacity-100 scale-100'
                          leave='transition ease-in duration-75'
                          leaveFrom='transform opacity-100 scale-100'
                          leaveTo='transform opacity-0 scale-95'
                        >
                          <Menu.Items className='z-10 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
                            {userNavigation.map((item) => (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <a
                                    href={item.href}
                                    className={classNames(
                                      active ? 'bg-gray-100' : '',
                                      'block px-4 py-2 text-sm text-gray-700'
                                    )}
                                  >
                                    {item.name}
                                  </a>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className='md:hidden'>
                <div className='px-2 pt-2 pb-3 space-y-1 sm:px-3 space-x-4'>
                  {navigation.map((item) => (
                    // eslint-disable-next-line react/jsx-key
                    <Link
                      to={{
                        pathname: item.to,
                      }}
                    >
                      <Disclosure.Button
                        key={item.name}
                        as='a'
                        href={item.href}
                        className={classNames(
                          item.current
                            ? 'bg-gray-900 text-white'
                            : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'block px-3 py-2 rounded-md text-base font-medium'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </Disclosure.Button>
                    </Link>
                  ))}
                </div>
                <div className='pt-4 pb-3 border-t border-gray-700'>
                  <div className='flex items-center px-5 sm:px-6'>
                    <div className='flex-shrink-0'>
                      <img
                        className='h-10 w-10 rounded-full'
                        src={user.imageUrl}
                        alt=''
                      />
                    </div>
                    <div className='ml-3'>
                      <div className='text-base font-medium text-white'>
                        {user.name}
                      </div>
                      <div className='text-sm font-medium text-gray-400'>
                        {user.email}
                      </div>
                    </div>
                    <button
                      type='button'
                      className='ml-auto flex-shrink-0 bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white'
                    >
                      <span className='sr-only'>View notifications</span>
                      <BellIcon className='h-6 w-6' aria-hidden='true' />
                    </button>
                  </div>
                  <div className='mt-3 px-2 space-y-1 sm:px-3'>
                    {userNavigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as='a'
                        href={item.href}
                        className='block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700'
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                </div>
              </Disclosure.Panel>

              <main className='flex-1 relative focus:outline-none h-auto bg-slate-200'>
                <div className='py-6 h-screen'>
                  <div className='max-w-full mx-0 px-2 sm:px-6 md:px-8 h-auto'>
                    <Routes>
                      <Route
                        path='/jobs'
                        element={<Jobs jobAdded={addJob} />}
                      />
                      <Route
                        path='/dockets'
                        element={<Dockets jobAdded={addJob} />}
                      />
                      {/* This is so that the root url will always go to home and show dashboard page*/}
                      <Route
                        path='/'
                        element={<Navigate replace to='/jobs' />}
                      />
                    </Routes>
                  </div>
                </div>
              </main>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
}
